import React from "react"
import Form from "react-netlify-form"

import { useI18n } from "../contexts/i18n"
import Button from "../components/forms/Button"
import Layout from "../layout"
import SEO from "../components/SEO"
import Textblock from "../components/Textblock"

const ContactPage = () => {
  const { translate: t } = useI18n(`contact`)

  return (
    <Layout narrow>
      <Textblock>
        <SEO title="Contact" />
        <h1>{t`Contact`}</h1>
        <Form name="contact" recaptcha={{ sitekey: `6LeHEo8UAAAAABzLXIALQwr-leMdWYQ2pYiTlTu0` }}>
          {({ loading, error, success, recaptcha, recaptchaError }) => (
            <div className="form-group">
              <div className="input-group full-input">
                <label htmlFor="name">{t`Name`}</label>
                <input className="input" type="text" name="name" required disabled={loading || success} />
              </div>
              <div className="input-group full-input">
                <label htmlFor="email">{t`Email`}</label>
                <input className="input" type="email" name="email" required disabled={loading || success} />
              </div>
              <div className="input-group full-input">
                <label htmlFor="message">{t`Message`}</label>
                <textarea className="input" name="message" required disabled={loading || success} />
              </div>
              {recaptcha}
              <div>
                <Button type="submit" disabled={loading || success}>
                  {t`Send`}
                </Button>
              </div>

              {loading && <p className="submission-feedback">{t`Loading`}...</p>}
              {error && (
                <p className="submission-feedback failure">{t`Your information was not sent. Please try again.`}</p>
              )}
              {recaptchaError && (
                <p className="submission-feedback failure">
                  {t`Recaptcha did not match. Please make sure the box is checked.`}
                </p>
              )}
              {success && <p className="submission-feedback">{t`Thank you for contacting us!`}</p>}
            </div>
          )}
        </Form>
      </Textblock>
    </Layout>
  )
}

export default ContactPage
